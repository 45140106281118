import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import {
  getErrorMessages,
  getInvalidFields,
  SecurityContext,
  useModal,
} from '@lib';
import { useFormik } from 'formik';
import { Link, navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext } from 'react';

import {
  DeleteUserDocument,
  GetTotalTradesDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import Modal from '@components/modal/Modal';

import Menu from './Menu';

type DeleteAccountPageProps = PageProps;

const DeleteAccountPage: FC<DeleteAccountPageProps> = ({ location }) => {
  const { currentUser, logout } = useContext(SecurityContext);

  const [deleteUser] = useMutation(DeleteUserDocument);
  const tradesNo = useQuery(GetTotalTradesDocument);

  const deleteModal = useModal();

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await deleteUser();
        await logout();
        // success
        navigate('/');
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[DeleteAccountPage] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Layout url="/profile/delete-account/" title="Delete Account" disableIndex>
      <Menu currentPath={location.pathname}>
        <div className="flex-1 flex flex-col mb-0">
          <div className="lg:pl-12 flex-1 flex flex-col">
            <h3 className="hidden lg:block font-emp text-2xl">
              Delete Account
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4">
              <p className="font-emp text-sm col-span-1 col-start-1">
                Would you like to delete your account {currentUser?.name}?
                <br />
                This account contains{' '}
                <Link to="/" className="underline text-interaction-light">
                  {tradesNo.data?.getTotalTrades} ongoing trades
                </Link>
                .
              </p>
              <p className="font-emp text-sm col-span-1 col-start-1">
                Your trades will still be visible in the platform but will be
                marked as settled under an anonymous name.
              </p>
              <p className="font-emp text-sm col-span-1 col-start-1">
                Deleting your account will remove all of your personal data
                according to GDPR.
              </p>
            </div>

            <div className="flex-1" />
            <div className="p-4 -mx-4 mt-8 lg:mt-20 lg:bg-transparent">
              <Button
                className="w-full bg-status-reject"
                variant="danger"
                disabled={formik.isSubmitting}
                onClick={deleteModal.open}
              >
                Permanently Delete Account
              </Button>
            </div>
          </div>
        </div>
      </Menu>
      <Modal
        size="max-w-lg w-full mx-4 align-center"
        hideCloseButton
        control={deleteModal}
        actions={
          <div className="flex space-x-6 w-full p-4 lg:p-0">
            <Button
              className="flex-1"
              type="reset"
              onClick={() => {
                deleteModal.close();
              }}
            >
              Cancel
            </Button>
            <>
              <Button
                className="flex-1 bg-status-reject"
                type="submit"
                name="submit"
                variant="primary"
                onClick={formik.submitForm}
              >
                Permanently Delete Account
              </Button>
            </>
          </div>
        }
      >
        <h3
          className="text-base lg:text-3xl font-emp font-bold mb-4 text-left"
          id={`modal-headline-delete-account`}
        >
          Permanently delete account?
        </h3>
        <p className={'text-sm opacity-75 font-emp'}>
          Deleting your account is permanent and cannot be undone. Are you sure
          you want to delete your Oilynx account?
        </p>
      </Modal>
    </Layout>
  );
};

export default DeleteAccountPage;
